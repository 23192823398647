<template>
  <div id="app">
    <el-container>
      <el-header><Header /></el-header>
      <el-container class="main">
        <el-aside width="251px" v-show="showMenu"><Menu /></el-aside>
        <el-main>
          <Feed v-show="showFeed" />
          <Source v-show="!showFeed" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Feed from "./components/Feed.vue";
import Menu from "./components/Menu.vue";
import Source from "./components/Source.vue";
import Header from "./components/Header.vue";
import { EventBus } from "./event-bus.js";
export default {
  name: "app",
  data() {
    return {
      showFeed: true,
      showMenu: true
    };
  },
  components: {
    Feed,
    Menu,
    Source,
    Header
  },
  created: function () {
    EventBus.$on("changeMenu", this.changeMenu);
    EventBus.$on("showMenu", this.switchMenu);
  },
  methods: {
    changeMenu(param) {
      if(param == '-1') {
        this.showFeed = false
      } else {
        this.showFeed = true
      }
    },
    switchMenu() {
      this.showMenu = !this.showMenu
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: -apple-system,BlinkMacSystemFont,"Helvetica Neue","PingFang SC","Hiragino Sans GB","Microsoft YaHei UI","Microsoft YaHei",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
  margin: 0;
}
.main {
  background-color: #ffffff;
  height: calc(100% - 59px);
}

li {
  margin: 0;
}
</style>
