import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import axios from 'axios'
import VueTimeago from 'vue-timeago'
import Clipboard from 'v-clipboard'
import VueRouter from 'vue-router'

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en',
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})

Vue.use(VueRouter)
Vue.use(Clipboard)

const router = new VueRouter({
  mode: 'history'
});

Vue.prototype.$axios= axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')