<template>
  <div class="main">
    <div class="recommend">
      <ul>
        <li v-for="(i, index) in collection" :key="index" @click="goRecommend(i.media, i.keyword)">{{ i.name }}</li>
      </ul>
    </div>
    <div v-infinite-scroll="loadFeed" infinite-scroll-distance="100" infinite-scroll-disabled="loading"
      class="list-content">
      <div v-if="!mail&&unionid" class="alert">
        <i class="el-icon-info"></i>
        公告：微信登录即将停止服务，请在3月1日前绑定邮箱，否则您的数据将会丢失。<a @click="mail_login_dlg = true"> 点击绑定</a>
      </div>
      <div class="keyword" v-if="
        keyword2 != '' &&
        keyword2.indexOf(',') == -1 &&
        follow_array.indexOf(keyword2) == -1
      ">
        <i class="el-icon-info"></i>
        订阅关键词“<strong>{{ this.keyword2 }}</strong>”到侧栏，快速访问相关新闻。<a @click="follow()"> 点击订阅</a>
      </div>
      <li v-for="(i, index) in info" :key="index" class="feed-item"
        @click="showDetail(i.id, i.title, i.url, i.source, i.sourceId)">
        <span @click.stop="goNewSourcePage(i.sourceId)" v-bind:class="[hasRead(i.id) ? 'read' : '']" class="source">{{
          i.source
        }}</span>
        <span v-bind:class="[hasRead(i.id) ? 'read' : '']">{{ i.title }}</span>
        <span v-bind:class="[hasRead(i.id) ? 'read' : '']" class="time">
          <timeago :datetime="i.date" locale="zh-CN"></timeago>
        </span>
        <span class="quick_view" @click.stop="openLink(i.url, i.id)"><i class="el-icon-link"></i> 点此新窗口打开链接</span>
      </li>
      <div class="tips" v-show="loading && !all">正在加载</div>
      <div class="tips" v-show="all">已加载完全部内容</div>
    </div>
    <!-- <div class="button" v-if="!loading && !all" @click="loadFeed()">下一页</div> -->
    <el-drawer :visible.sync="drawer" :with-header="false" :size="'50%'" :before-close="handleClose">
      <div class="modal-header" v-if="drawer">
        <span>本文来自<a class="link" @click="goNewSourcePage(detail_loading.sourceId)">{{ detail_loading.source }}
          </a>，原文链接:
          <a class="link" :href="detail_loading.url" target="_blank">{{ detail_loading.url }}
          </a>
          <el-button v-clipboard:success="clipboardSuccessHandler" v-clipboard="detail_loading.url" type="text">
            复制网址</el-button>
        </span>
      </div>
      <div class="content" v-if="drawer">
        <h1>{{ detail_loading.title }}</h1>

        <div v-if="detail" v-html="detail.content"></div>
        <el-skeleton v-if="!detail" :rows="8" animated />
      </div>
    </el-drawer>
    <el-dialog title="邮箱绑定" :append-to-body="true" :visible.sync="mail_login_dlg" width="500px"
      @open="handleMailDlgOpen" :before-close="handleMailDlgClose">
      <el-input v-model="mail" ref="mail_input" type="email" placeholder="邮箱地址"></el-input>
      <div style="margin: 20px 0;"></div>
      <el-input v-model="verify_code" ref="verify_code_input" oninput="value=value.replace(/[^\d]/g,'')" maxlength="4"
        placeholder="4位数验证码"><el-button style="color: #409eff;" slot="append"
          @click="sendVerifyCode">发送验证码</el-button></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkVerifyCode" :disabled="verify_code.length != 4">绑 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="设置密码（可选）" :append-to-body="true" @open="handlePasswordDlgOpen" :visible.sync="mail_pass_dlg"
      width="500px">
      <el-alert title="邮箱绑定成功" type="success" description="若您不想每次登录都发送验证码，可设置一个密码" show-icon>
      </el-alert>
      <div style="margin: 20px 0;"></div>
      <el-input v-model="password" ref="password_input" show-password placeholder="请输入密码（最少6位，密码将被加密存储）"></el-input>
      <div style="margin: 10px 0;"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mail_pass_dlg = false">取 消</el-button>
        <el-button type="primary" @click="checkVerifyCode" :disabled="password.length <= 6">设置密码</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
export default {
  name: "Feed",
  data() {
    return {
      info: [],
      end: "",
      loading: false,
      drawer: false,
      detail: null,
      detail_loading: null,
      source_id: "",
      limit: 25,
      all: false,
      read_array: [],
      mode: "feed", //feed/search
      keyword: "",
      source: [],
      follow_array: [],
      keywordStr: "",
      media: "",
      keyword2: "",
      collection: [],
      mail_login_dlg: false,
      mail: "",
      verify_code: "",
      verify_code_sent: false,
      mail_pass_dlg: false,
      password: "",
      unionid: ""
    };
  },
  created: function () {
    if (localStorage.read_array) {
      this.read_array = localStorage.read_array.split(",");
    }
    if (localStorage.follow_array) {
      this.follow_array = localStorage.follow_array.split(",");
    }
    if (localStorage.source) {
      this.source = localStorage.source.split(",");
      this.source_id = this.source;
    }
    if (localStorage.mail) {
      this.mail = localStorage.mail;
    }
    if (localStorage.unionid) {
      this.unionid = localStorage.unionid;
    }
  },
  mounted: function () {
    if (this.$route.query.keyword) {
      this.keyword2 = this.$route.query.keyword;
    }
    if (this.$route.query.media) {
      this.media = this.$route.query.media;
    }
    this.getFeed();
    this.getCollection();
  },
  watch: {
    $route() {
      this.info = [];
      this.end = "";
      this.media = "";
      this.keyword = "";
      // console.log(to.path);
      if (this.$route.query.keyword) {
        this.keyword2 = this.$route.query.keyword;
      } else {
        this.keyword2 = ""
      }
      if (this.$route.query.media) {
        this.media = this.$route.query.media;
      } else {
        this.media = ""
      }
      if (this.$route.query.all) {
        this.media = "",
          this.keyword2 = ""
      }
      this.getFeed();
    },
  },
  methods: {
    showDetail(id, title, url, source, source_id) {
      this.drawer = true;
      this.detail_loading = {
        title: title,
        url: url,
        source: source,
        sourceId: source_id
      }
      this.$axios
        .get("https://api.yinrss.com/detail?id=" + id)
        .then((response) => {
          this.detail = response.data.data;
        });

      this.read_array.push(id);
      localStorage.setItem("read_array", this.read_array);
    },
    openLink(url, id) {
      this.read_array.push(id);
      localStorage.setItem("read_array", this.read_array);
      window.open(url, "_blank");
    },
    loadFeed() {
      if (this.loading == false && this.all == false) {
        this.getFeed();
      }
    },
    getFeed() {
      this.loading = true;
      this.$axios
        .get(
          "https://api.yinrss.com/feed?limit=" +
          this.limit +
          "&id=" +
          this.end +
          "&media=" +
          this.media +
          "&keyword=" +
          this.keyword2
        )
        .then((response) => {
          this.info = this.info.concat(response.data.data);
          this.end = response.data.data[response.data.data.length - 1].id;
          if (response.data.data.length == this.limit) {
            this.loading = false;
          } else {
            this.all = true;
            this.loading = false;
          }
        });
    },
    getCollection() {
      this.$axios
        .get("https://api.yinrss.com/collection")
        .then((response) => {
          this.collection = response.data.data;
        });
    },
    handleClose() {
      this.detail = null;
      this.drawer = false;
    },
    hasRead(id) {
      return this.read_array.includes(id);
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: "复制成功",
        type: "success",
        duration: 1500,
      });
    },
    follow() {
      if (this.follow_array.indexOf(this.keyword2) == -1) {
        this.follow_array.push(this.keyword2);
        localStorage.setItem("follow_array", this.follow_array);
        EventBus.$emit("follow");
        location.reload();
        this.updateKeyword();
      } else {
        alert("关键词已经存在");
      }
    },
    updateKeyword() {
      const unionid = localStorage.unionid;
      const mail = localStorage.mail;
      this.$axios
        .get(
          "https://api.yinrss.com/user/updateKeyword?unionid=" +
          unionid +
          "&mail=" +
          mail +
          "&keyword=" +
          localStorage.follow_array
        )
        .then(() => { });
    },
    goNewSourcePage(sourceId) {
      const url = "/?media=" + sourceId;
      window.open(url, "_blank");
    },
    goRecommend(media, keyword) {
      console.log(media, keyword);
      if (media == null) {
        media = ""
      }
      if (keyword == null) {
        keyword = ""
      }
      this.$router.push(
        "/?media=" + media + "&keyword=" + keyword
      );
    },
    sendVerifyCode() {
      var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (reg.test(this.mail)) {
        this.$axios
          .get("https://api.yinrss.com/user/sendMailCode?mail=" + this.mail)
          .then((response) => {
            const sendStatus = response.data.errno;
            if (sendStatus == 0) {
              this.verify_code_sent = true
              this.$refs.verify_code_input.$el.children[0].focus();
              this.$message({
                message: '验证码已发送，请前往邮箱查看',
                type: 'success'
              });
            } else {
              this.$message.error('邮件发送失败，请稍后再试');
            }
          });

      } else {
        this.$message.error('邮箱格式错误，请检查');
      }
    },
    checkVerifyCode() {
      this.$axios
          .get("https://api.yinrss.com/user/verifyMailCode?mail=" + this.mail +"&code=" + this.verify_code + "&unionid=" + this.unionid)
          .then((response) => {
            const sendStatus = response.data.errno;
            if (sendStatus == 0) {
              this.mail_login_dlg = false
              localStorage.setItem("mail", this.mail);
              this.$message({
                message: '绑定成功',
                type: 'success'
              });
            } else {
              this.$message.error('验证码错误，请检查');
            }
          });
      

      // 如果未设置密码就弹设置密码流程
      // if (!this.mail_pass_dlg) {
      //   this.mail_pass_dlg = true
      // }
    },
    handleMailDlgClose() {
      this.mail_login_dlg = false
      this.mail = ""
      this.verify_code = ""
      this.verify_code_sent = false
    },
    handleMailDlgOpen() {
      this.$nextTick(function () {
        this.$refs.mail_input.$el.children[0].focus();
      });
    },
    handlePasswordDlgOpen() {
      this.$nextTick(function () {
        this.$refs.password_input.$el.children[0].focus();
      });
    }
  },
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
.feed-item {
  display: block;
  font-weight: 500;
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 20px;
  border-radius: 3px;
}

.feed-item:hover {
  background: #f9f9f9;
  box-sizing: border-box;
  cursor: pointer;
}

.quick_view {
  margin-left: 20px;
  font-weight: normal;
  display: none;
  float: right;
  font-size: 14px;
  color: #576b95;
}

.feed-item:hover .quick_view {
  display: inline-block;
}

.source {
  color: #9e9e9e;
  font-weight: normal;
  display: inline-block;
  width: 130px;
}

.link {
  color: #576b95 !important;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.read {
  opacity: 0.3;
}

.time {
  margin-left: 20px;
  color: #9e9e9e;
  font-weight: normal;
}

.tips {
  color: #9e9e9e;
  text-align: center;
  padding: 10px;
}

.list-content {
  overflow: auto;
  height: calc(100vh - 150px);
  padding: 20px;
}

.content {
  overflow: auto;
  max-width: 650px;
  margin: 0 auto;
  font-size: 15px;
  line-height: 2em;
  color: #333;
  font-weight: 400;
  margin-bottom: 80px;
  margin-top: 60px;
  letter-spacing: 0.544px;
  -webkit-font-smoothing: auto;
}

.content img {
  max-width: 650px;
  width: 100%;
  height: auto;
}

.content p {
  width: 100%;
}

.content video {
  max-width: 650px;
}

.main {
  height: 100%;
  margin: 0;
}

.keyword {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;

  a {
    color: #576b95;
  }
}

.alert {
  margin-bottom: 10px;
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 10px;
  border-radius: 5px;

  a {
    color: #576b95;
  }
}

.button {
  background: #f1f1f1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  margin-bottom: 0;
}

.button:hover {
  background-color: #d8d8d8;
}

.content h1 {
  font-size: 1.6em;
  line-height: 1.5em;
}

.content ul {
  list-style-type: none;
  padding: 0;
}

.content li {
  display: inline-block;
  margin: 0 10px;
}

.content a {
  color: #576b95;
  text-decoration: none;
}

.content p {
  margin-bottom: 30px;
}

.content div {
  margin-bottom: 25px;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.el-drawer__body {
  overflow: auto;
}

.modal-header {
  margin-bottom: 0;
  padding: 0 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  font-size: 14px;
}

.recommend {
  border-bottom: 1px #f1f1f1 solid;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  ul {
    display: flex;
    overflow-x: auto;
    list-style-type: none;
    padding-left: 5px;
  }

  li {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }

    ::-webkit-scrollbar
  {
    display: none;
  }
}
</style>
