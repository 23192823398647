<template>
  <div class="menu">
    <li
      v-bind:class="[source_id == 'all' ? 'menu-active' : '']"
      class="source-item"
      @click="getAllFeed()"
    >
      <span> <i class="el-icon-s-home"></i> 全部内容</span>
    </li>
    <li
      v-bind:class="[source_id == 'my' ? 'menu-active' : '']"
      v-show="this.source.length || this.follow_array.length >= 1"
      class="source-item"
      @click="getMyFeed()"
    >
      <span> <i class="el-icon-s-flag"></i> 我的订阅</span>
    </li>
    <li
      v-for="(i, index) in customizeGroup"
      :key="index"
      v-bind:class="[
        current == i.name && source_id == '-1' ? 'menu-active' : '',
      ]"
      class="source-item"
      @click="getCustomizeGroupFeed(i)"
    >
      <span class="source-name"> ✦ {{ i.name }}</span>
      <span
        ><i
          @click="editCustomize(i, index)"
          class="el-icon-setting delete_keyword"
        ></i>
        <i @click="deleteCustomize(i)" class="el-icon-delete delete_keyword"></i
      ></span>
    </li>
    <li class="source-item" @click="dialogVisible = true">
      <span> <i class="el-icon-circle-plus"></i> 自定义分组</span>
    </li>
    <div class="divider"></div>
    <div class="sub-title">
      <span
        >关键词
        <el-tooltip content="在搜索结果页可以订阅关键词" placement="top"
          ><i class="el-icon-question"></i></el-tooltip
      ></span>
    </div>
    <li
      v-for="(i, index) in follow_array"
      :key="index"
      v-bind:class="[source_id == i ? 'menu-active' : '']"
      class="source-item"
      @click="search(i)"
      v-show="index < 7 || showMoreKeyword"
    >
      <span> <i class="el-icon-search"></i> {{ i }} </span
      ><span @click="deleteKeyword(i)" class="delete_keyword"
        ><i class="el-icon-delete"></i
      ></span>
    </li>
    <li
      v-show="showMoreKeyword == true && follow_array.length > 7"
      @click="toogleKeyword()"
      class="source-item link"
    >
      <span> <i class="el-icon-arrow-up"></i> 收起 </span>
    </li>
    <li
      v-show="showMoreKeyword == false && follow_array.length > 7"
      @click="toogleKeyword()"
      class="source-item link"
    >
      <span> <i class="el-icon-arrow-down"></i> 展开 </span>
    </li>
    <div class="divider"></div>
    <div class="sub-title">
      <span>媒体</span>
      <el-tooltip content="管理订阅源" placement="top"
        ><i
          @click="mediaDialogVisible = true"
          class="el-icon-setting setting"
        ></i
      ></el-tooltip>
    </div>
    <li
      v-for="(i, index) in info"
      :key="index"
      v-bind:class="[i.id == source_id ? 'menu-active' : '']"
      v-show="hasFollow(i.id) || source.length == 0"
      class="source-item"
      @click="changeMenu(i.id)"
    >
      <span class="source-name"
        ><img class="logo" :src="i.logo" alt="i.name" />{{ i.name }}</span
      >
    </li>
    
    <el-dialog
      title="自定义分组"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      关键词
      <li
        v-for="(i, index) in follow_array"
        v-bind:class="[hasCheckKeyword(i) ? 'checked' : '']"
        :key="index"
        class="source-item"
        @click="checkKeyword(i)"
      >
        <span> <i class="el-icon-search"></i> {{ i }} </span>
        <i v-show="hasCheckKeyword(i)" class="el-icon-success"></i>
      </li>
      媒体
      <li
        v-for="(i, index) in info"
        v-bind:class="[hasCheckMedia(i.id) ? 'checked' : '']"
        :key="index"
        class="source-item"
        @click="checkMedia(i.id)"
      >
        <span class="source-name"
          ><img class="logo" :src="i.logo" alt="i.name" />{{ i.name }}</span
        >
        <i v-show="hasCheckMedia(i.id)" class="el-icon-success"></i>
      </li>
      <div class="footer-btn">
        <el-button type="primary" @click="innerVisible = true"
          >下一步</el-button
        >
      </div>
      <el-dialog
        width="20%"
        title="请输入自定义分组名称"
        :visible.sync="innerVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <el-input
          autofocus="true"
          v-model="customizeName"
          maxlength="10"
          placeholder="请输入名称，最多10个字"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveCustomize()">保 存</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <el-dialog
      title="订阅源管理"
      :visible.sync="mediaDialogVisible"
      :append-to-body="true"
      width="80%"
    >
      <div class="source-area">
        <div class="title">已订阅</div>
        <div v-show="source.length == 0" class="none">
          暂无订阅，首页将展示全部媒体的内容
        </div>
        <ul>
          <li
            v-for="(i, index) in info"
            :key="index"
            v-show="hasFollow(i.id)"
            @click="clickSource(i.id)"
          >
            <span class="media-item">
              <img class="media-icon" :src="i.logo" alt="i.name" />
              <span class="name">
                {{ i.name }}
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="source-area">
        <div class="title">未订阅</div>
        <ul>
          <li
            v-for="(i, index) in info"
            :key="index"
            v-show="!hasFollow(i.id)"
            @click="clickSource(i.id)"
          >
            <span class="media-item">
              <img class="media-icon" :src="i.logo" alt="i.name" />
              <span class="name">
                {{ i.name }}
              </span>
            </span>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
export default {
  name: "Menu",
  data() {
    return {
      info: [],
      source_id: "all",
      source: [],
      follow_array: [],
      unionid: "",
      mail: "",
      dialogVisible: false,
      innerVisible: false,
      mediaDialogVisible: false,
      customizeKeyword: [],
      customizeMedia: [],
      customizeName: "",
      customizeGroup: [],
      current: "",
      indexa: -1,
      showMoreKeyword: false,
    };
  },
  created: function () {
    this.getSource();
    if (localStorage.unionid || localStorage.mail) {
      if(localStorage.unionid) {
        this.unionid = localStorage.unionid;
      }
      if(localStorage.mail) {
        this.mail = localStorage.mail;
      }
      this.getUserInfo();
    } else {
      if (localStorage.source) {
        this.source = localStorage.source.split(",");
      }
      if (localStorage.follow_array) {
        this.follow_array = localStorage.follow_array.split(",");
      }
    }
    if (localStorage.group) {
      this.customizeGroup = JSON.parse(localStorage.group);
    }
  },
  mounted: function () {
    if (
      this.$route.query.keyword &&
      this.$route.query.keyword.indexOf(",") == -1
    ) {
      this.source_id = this.$route.query.keyword;
    }
    if (this.$route.query.media && this.$route.query.media.indexOf(",") == -1) {
      this.source_id = this.$route.query.media;
    }
  },
  methods: {
    getSource() {
      this.$axios.get("https://api.yinrss.com/source").then((response) => {
        this.info = response.data.data;
      });
    },
    getUserInfo() {
      this.$axios
        .get("https://api.yinrss.com/user/info?unionid=" + this.unionid + "&mail=" + this.mail)
        .then((response) => {
          console.log(response);
          localStorage.setItem("source", response.data.data.media);
          if(response.data.data.media){
            this.source = response.data.data.media.split(",");
          }
          localStorage.setItem("follow_array", response.data.data.keyword);
          if(response.data.data.keyword){
            this.follow_array = response.data.data.keyword.split(",");
          }
        });
    },
    changeMenu(source_id) {
      this.source_id = source_id;
      this.$router.push("/?media=" + source_id);
    },
    goSourceConfig() {
      this.source_id = "-1";
      EventBus.$emit("changeMenu", "-1");
    },
    getMyMediaFeed() {
      this.source_id = "0";
      this.$router.push("/?media=" + this.source);
    },
    getMyKeywordFeed() {
      this.source_id = "keyword";
      this.$router.push("/?keyword=" + this.follow_array);
    },
    getAllFeed() {
      this.source_id = "all";
      this.$router.push("/?all=true");
    },
    getMyFeed() {
      this.source_id = "my";
      this.$router.push(
        "/?media=" + this.source + "&keyword=" + this.follow_array
      );
    },
    getCustomizeGroupFeed(i) {
      this.current = i.name;
      this.source_id = "-1";
      this.$router.push(
        "/?media=" + i.media.toString() + "&keyword=" + i.keyword.toString()
      );
    },
    hasFollow(id) {
      return this.source.includes(id);
    },
    search(keyword) {
      this.source_id = keyword;
      this.$router.push("/?keyword=" + keyword);
    },
    getFollowArray() {
      this.follow_array = localStorage.follow_array.split(",");
    },
    deleteKeyword(keyword) {
      const i = this.follow_array.indexOf(keyword);
      this.follow_array.splice(i, 1);
      localStorage.setItem("follow_array", this.follow_array);
      this.updateKeyword();
    },
    updateKeyword() {
      const unionid = localStorage.unionid;
      const mail = localStorage.mail;
      this.$axios
        .get(
          "https://api.yinrss.com/user/updateKeyword?unionid=" +
            unionid +
            "&mail=" +
            mail +
            "&keyword=" +
            localStorage.follow_array
        )
        .then(() => {});
    },
    checkKeyword(keyword) {
      if (this.customizeKeyword.includes(keyword)) {
        const i = this.customizeKeyword.indexOf(keyword);
        this.customizeKeyword.splice(i, 1);
      } else {
        this.customizeKeyword.push(keyword);
      }
    },
    checkMedia(media) {
      if (this.customizeMedia.includes(media)) {
        const i = this.customizeMedia.indexOf(media);
        this.customizeMedia.splice(i, 1);
      } else {
        this.customizeMedia.push(media);
      }
    },
    hasCheckKeyword(keyword) {
      return this.customizeKeyword.includes(keyword);
    },
    hasCheckMedia(media) {
      return this.customizeMedia.includes(media);
    },
    saveCustomize() {
      const group = {
        name: this.customizeName,
        keyword: this.customizeKeyword,
        media: this.customizeMedia,
      };
      if (this.index >= 0) {
        this.customizeGroup.splice(this.index, 1);
      }
      this.customizeGroup.push(group);
      localStorage.setItem("group", JSON.stringify(this.customizeGroup));
      this.customizeName = "";
      this.customizeKeyword = [];
      this.customizeMedia = [];
      this.innerVisible = false;
      this.dialogVisible = false;
      this.index = -1;
    },
    editCustomize(i, index) {
      this.index = index;
      this.dialogVisible = true;
      this.customizeName = i.name;
      this.customizeKeyword = i.keyword;
      this.customizeMedia = i.media;
    },
    deleteCustomize(group) {
      const i = this.customizeGroup.indexOf(group);
      this.customizeGroup.splice(i, 1);
      localStorage.setItem("group", JSON.stringify(this.customizeGroup));
    },
    toogleKeyword() {
      this.showMoreKeyword = !this.showMoreKeyword;
    },
    clickSource(id) {
      if (this.source.includes(id)) {
        const i = this.source.indexOf(id);
        this.source.splice(i, 1);
      } else {
        this.source.push(id);
      }
      localStorage.setItem("source", this.source);
      this.updateMedia();
    },
    updateMedia() {
      const unionid = localStorage.getItem("unionid");
      const mail = localStorage.getItem("mail");
      this.$axios
        .get(
          "https://api.yinrss.com/user/updateMedia?unionid=" +
            unionid +
            "&mail=" +
            mail +
            "&media=" +
            localStorage.getItem("source")
        )
        .then(() => {});
    }
  },
  props: {
    msg: String,
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 14px;
  margin-left: 24px;
  margin-bottom: 4px;
  margin-top: 20px;
  margin-right: 24px;
  font-weight: 500;
  color: #666;
  display: flex;
  justify-content: space-between;
}
.setting {
  font-size: 16px;
  color: #bbbbbb;
}

.setting:hover {
  color: #666;
  cursor: pointer;
}
.logo {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border-radius: 50%;
  border: 0.5px solid #f1f1f1;
}
.source-item {
  display: flex;
  font-weight: 500;
  padding: 8px 36px 8px 24px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}

.source-item li {
  margin: 0;
}

.delete_keyword {
  display: none;

  &:hover {
    color: #f30;
  }
}

.source-item:hover .delete_keyword {
  display: inline-block;
}

.source-item:hover {
  cursor: pointer;
  background-color: #0000000d;
}

.source-name {
  color: #333333;
  display: flex;
  align-items: center;
}

.link {
  color: #576b95;
}

.menu {
  height: calc(100% - 84px);
  margin: 0;
  overflow: auto;
  background-color: #f9f9f9;
  border-right: 1px #f1f1f1 solid;
  position: fixed;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #333;
  width: 250px;
}
.menu-active {
  background: rgba(0, 0, 0, 0.1);
}
.button {
  background: #f1f1f1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  margin-bottom: 0;
}

.button:hover {
  background-color: #d8d8d8;
}

h1 {
  font-size: 2em;
  line-height: 1.5em;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  padding: 10px;
}
a {
  color: #000000;
  text-decoration: none;
}

p {
  margin-bottom: 30px;
}

div {
  margin-bottom: 30px;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.el-drawer__body {
  overflow: auto;
}
.divider {
  border-top: 1px solid #f1f1f1;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checked {
  // color: #2440b3;
  background: #f1f1f1;
}
.footer-btn {
  position: sticky;
  bottom: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.icp {
  font-size: 14px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
}

.menu::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.source-area {
  ul {
    display: flex;
    width: 100%;
    flex-flow: wrap;
  }

  li {
    width: 10.8%;
    font-size: 13px;
    color: #333333;
  }
}

.media-icon {
  width: 36px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 0.5px solid #e6e6e6;
}

.media-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
