<template>
  <div class="main">
    <div class="source-area">
      <div class="title">已订阅</div>
      <div v-show="source.length == 0" class="none">
        暂无订阅，首页将展示全部媒体的内容
      </div>
      <div
        v-for="(i, index) in info"
        :key="index"
        v-show="hasFollow(i.id)"
        @click="clickSource(i.id)"
        class="source-item"
      >
        <img class="logo" :src="i.logo" alt="i.name" />
        <span class="name">
          {{ i.name }}
        </span>
      </div>
    </div>
    <div class="source-area">
      <div class="title">未订阅</div>
      <div
        v-for="(i, index) in info"
        :key="index"
        v-show="!hasFollow(i.id)"
        @click="clickSource(i.id)"
        class="source-item"
      >
        <img class="logo" :src="i.logo" alt="i.name" />
        <span class="name">
          {{ i.name }}
        </span>
      </div>
    </div>
    <div class="button-area">
      <div class="button" @click="refresh()">应用</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: [],
      source: [],
    };
  },
  created: function () {
    if (localStorage.getItem("source")) {
      this.source = localStorage.getItem("source").split(",");
    }
    this.getSource();
  },
  methods: {
    goBack() {
      console.log("go back");
    },
    getSource() {
      this.$axios.get("https://api.yinrss.com/source").then((response) => {
        this.info = response.data.data;
      });
    },
    clickSource(id) {
      if (this.source.includes(id)) {
        const i = this.source.indexOf(id);
        this.source.splice(i, 1);
      } else {
        this.source.push(id);
      }
      localStorage.setItem("source", this.source);
      this.updateMedia()
    },
    hasFollow(id) {
      return this.source.includes(id);
    },
    refresh() {
      location.reload();
    },
    updateMedia() {
      const unionid = localStorage.getItem("unionid")
      const mail = localStorage.getItem("mail")
      this.$axios
        .get(
          "https://api.yinrss.com/user/updateMedia?unionid=" +
            unionid +
            "&mail=" +
            mail +
            "&media=" +
            localStorage.getItem("source")
        )
        .then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.source-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 0 0 80px;
  align-items: center;
  box-sizing: border-box;
  height: 80px;
  border-radius: 5px;
  .logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0.5px solid #f1f1f1;
  }
  .name {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
  }
  &:hover {
    background: #f1f1f1;
    cursor: pointer;
  }
}

.source-area {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .title {
    flex-basis: 100%;
  }

  .none {
    color: #999;
    font-size: 14px;
    margin-left: 20px;
  }
}

.button-area {
  flex-basis: 100%;
  margin-right: 40px;
}
</style>>
