<template>
  <div class="header">
    <div class="menu-btn" @click="showMenu()">
      <hamburger-button class="" theme="outline" size="24" fill="#333" strokeLinejoin="bevel" strokeLinecap="square" />
    </div>
    <a class="logo" href="/"><img src="https://app.yinrss.com/logo.png" alt="" /></a>
    <div class="search-container">
      <input class="search" v-model="keyword" type="text" placeholder="请输入关键词，按回车搜索，支持订阅" @keyup.enter="search" />
    </div>
    <div class="right-area">
      <a class="right" href="https://app.yinrss.com/" target="about_blank">
        <i class="el-icon-download"></i> 下载APP
      </a>
      <a class="right" target="about_blank" href="https://support.qq.com/products/312976"><i
          class="el-icon-question"></i> 帮助反馈</a>

      <a v-if="!this.avatar" class="right" @click="mail_login_dlg = true"><el-tooltip
          content="登录后可在不同设备、浏览器、手机app间同步订阅信息" placement="top"><i class="el-icon-user-solid"> 登录</i>
        </el-tooltip></a>

      <el-dropdown @command="logout" trigger="click">
        <el-avatar class="avatar" v-if="this.avatar" :size="30" :src="this.avatar"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="登录" :append-to-body="true" :visible.sync="mail_login_dlg" width="500px" @open="handleMailDlgOpen"
      :before-close="handleMailDlgClose">

      <el-input v-model="mail" ref="mail_input" type="email" placeholder="邮箱地址"></el-input>
      <div style="margin: 20px 0;"></div>
      <el-input v-model="verify_code" ref="verify_code_input" oninput="value=value.replace(/[^\d]/g,'')" maxlength="6"
        placeholder="6位数验证码"><el-button style="color: #409eff;" slot="append"
          @click="sendVerifyCode">发送验证码</el-button></el-input>
      <span slot="footer" class="dialog-footer">
        <el-row type="flex" justify="space-between">
          <el-button @click="goWxLogin" type="text">老用户微信登录入口</el-button>
          <el-button type="primary" @click="mailLogin" :disabled="verify_code.length != 4">邮箱登录</el-button>
        </el-row>
      </span>
    </el-dialog>
    <!-- <el-dialog title="设置密码（可选）" :append-to-body="true" @open="handlePasswordDlgOpen" :visible.sync="mail_pass_dlg"
      width="500px">
      <el-alert title="邮箱绑定成功" type="success" description="若您不想每次登录都发送验证码，可设置一个密码" show-icon>
      </el-alert>
      <div style="margin: 20px 0;"></div>
      <el-input v-model="password" ref="password_input" show-password placeholder="请输入密码（最少6位，密码将被加密存储）"></el-input>
      <div style="margin: 10px 0;"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mail_pass_dlg = false">取 消</el-button>
        <el-button type="primary" @click="" :disabled="password.length <= 6">设置密码</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
import { HamburgerButton } from '@icon-park/vue';
export default {
  components: {
    HamburgerButton
  },
  name: "Menu",
  data() {
    return {
      keyword: "",
      mail_login_dlg: false,
      avatar: "",
      unionid: "",
      mail: "",
      verify_code: "",
      verify_code_sent: false,
      mail_pass_dlg: false,
      password: ""
    };
  },
  created: function () {
    if (localStorage.avatar) {
      this.avatar = localStorage.avatar;
    }
    if (localStorage.unionid) {
      this.unionid = localStorage.unionid;
    }
    if (localStorage.mail) {
      this.mail = localStorage.mail;
    }
  },
  mounted: function () {
    if (this.$route.query.code && this.$route.query.state) {
      const code = this.$route.query.code;
      const state = this.$route.query.state;
      this.getToken(code, state);
    }
  },
  methods: {
    search() {
      if (this.keyword.trim()) {
        this.$axios
          .get("https://api.yinrss.com/addSearchHistory?keyword=" + this.keyword.trim())
          .then(() => { });
        this.$router.push('/?keyword=' + this.keyword.trim())
      }
    },
    goWxLogin() {
      this.$axios.get("https://api.yinrss.com/qrcode").then((response) => {
        // console.log(response.data)
        const url = response.data;
        window.location.href = url;
      });
    },
    getToken(code, state) {
      this.$axios
        .get(
          "https://api.yinrss.com/wechatLogin?code=" + code + "&state=" + state
        )
        .then((response) => {
          console.log(response);
          // 本地没有，服务端有，直接覆盖本地
          if (response.data.data.media) {
            localStorage.setItem("source", response.data.data.media);
          }
          if (response.data.data.keyword) {
            localStorage.setItem("follow_array", response.data.data.keyword);
          }

          // 本地有，服务端没有，直接覆盖服务端
          if (!response.data.data.media && localStorage.source) {
            this.$axios
              .get(
                "https://api.yinrss.com/user/updateMedia?unionid=" +
                response.data.data.unionid +
                "&media=" +
                localStorage.source
              )
              .then(() => { });
          }
          if (
            !response.data.data.keyword &&
            localStorage.follow_array
          ) {
            this.$axios
              .get(
                "https://api.yinrss.com/user/updateKeyword?unionid=" +
                response.data.data.unionid +
                "&keyword=" +
                localStorage.follow_array
              )
              .then(() => { });
          }

          this.avatar = response.data.data.avatar;
          localStorage.setItem("avatar", response.data.data.avatar);
          localStorage.setItem("unionid", response.data.data.unionid);
          if(response.data.data.mail) {
            localStorage.setItem("mail", response.data.data.mail);
          }
          this.$router.replace({ path: "/" });
          location.reload();
        });
    },
    logout() {
      localStorage.setItem("unionid", "");
      localStorage.setItem("avatar", "");
      localStorage.setItem("mail", "");
      location.reload();
    },
    showMenu() {
      EventBus.$emit("showMenu");
    },
    sendVerifyCode() {
      var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (reg.test(this.mail)) {
        this.$axios
          .get("https://api.yinrss.com/user/sendMailCode?mail=" + this.mail)
          .then((response) => {
            const sendStatus = response.data.errno;
            if (sendStatus == 0) {
              this.verify_code_sent = true
              this.$refs.verify_code_input.$el.children[0].focus();
              this.$message({
                message: '验证码已发送，请前往邮箱查看',
                type: 'success'
              });
            } else {
              this.$message.error('邮件发送失败，请稍后再试');
            }
          });

      } else {
        this.$message.error('邮箱格式错误，请检查');
      }
    },
    mailLogin() {
      this.$axios
        .get("https://api.yinrss.com/user/mailLogin?mail=" + this.mail + "&code=" + this.verify_code)
        .then((response) => {
          console.log(response.data.data);
          const verifyStatus = response.data.errno;
          // 验证成功
          if (verifyStatus == 0) {
            console.log(response);
            // 本地没有，服务端有，直接覆盖本地
            if (response.data.data.media) {
              localStorage.setItem("source", response.data.data.media);
            }
            if (response.data.data.keyword) {
              localStorage.setItem("follow_array", response.data.data.keyword);
            }

            // 本地有，服务端没有，直接覆盖服务端
            if (!response.data.data.media && localStorage.source) {
              this.$axios
                .get(
                  "https://api.yinrss.com/user/updateMedia?mail=" +
                  response.data.data.mail +
                  "&media=" +
                  localStorage.source
                )
                .then(() => { });
            }
            if (
              !response.data.data.keyword &&
              localStorage.follow_array
            ) {
              this.$axios
                .get(
                  "https://api.yinrss.com/user/updateKeyword?mail=" +
                  response.data.data.mail +
                  "&keyword=" +
                  localStorage.follow_array
                )
                .then(() => { });
            }

            this.avatar = response.data.data.avatar;
            localStorage.setItem("avatar", response.data.data.avatar);
            localStorage.setItem("password", response.data.data.password);
            localStorage.setItem("mail", response.data.data.mail);
            this.$router.replace({ path: "/" });
            location.reload();
            this.mail_login_dlg = false
            // 如果未设置密码就弹设置密码流程
            // if (!this.mail_pass_dlg) {
            //   this.mail_pass_dlg = true
            // }
          } else {
            this.$message.error('验证码错误，请检查后重试');
          }
        });

    },
    handleMailDlgClose() {
      this.mail_login_dlg = false
      this.mail = ""
      this.verify_code = ""
      this.verify_code_sent = false
    },
    handleMailDlgOpen() {
      this.$nextTick(function () {
        this.$refs.mail_input.$el.children[0].focus();
      });
    },
    handlePasswordDlgOpen() {
      this.$nextTick(function () {
        this.$refs.password_input.$el.children[0].focus();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  flex: 1;
}

.right-area {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search {
  width: 500px;
  outline-style: none;
  background-color: #f9f9f9;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 16px;

  &::placeholder {
    color: #9e9e9e;
  }
}

.header {
  position: fixed;
  z-index: 1500;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #f1f1f1;
  background-color: #ffffff;
  text-align: center;
  display: flex;
}

.menu-btn {
  display: flex;
  align-items: center;
  margin: 10px 2px;
  margin-left: 20px;
  padding: 0 8px;

  &:hover {
    background-color: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
  }
}

.logo img {
  height: 40px;
  width: auto;
  margin-top: 10px;
  margin-left: 20px;
}

.right {
  margin: 10px 2px;
  text-decoration: none;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;

  &:hover {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
}

.avatar {
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}

.link {
  color: #2f5af5 !important;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}
</style>
